import React from 'react';
import OfferType from 'renewal/domain/model/OfferType';
import OfferSource from 'renewal/domain/model/OfferSource';

const earlyRenewalFaqs = [
  {
    question: 'Will this change my renewal date?',
    answer: 'No, your renewal date will remain the same but you will be charged today.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today.',
  },
  {
    question: 'What if I choose not to take this offer?',
    answer: 'You will be charged on the date shown on your pass, unless you decide to cancel.',
  },
];

const upgradeFaqs = [
  {
    question: 'Will this change my renewal date?',
    answer: 'By starting one of our Season passes today, your next renewal date will be a year from today. Your original pass will not be continued.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today.',
  },
  {
    question: 'How are our season passes different from my current pass?',
    answer: 'Our season passes are the most cost-effective options, covering you for unlimited days of carving throughout the whole 365-day window.',
  },
  {
    question: 'How is my pass upgrade price calculated?',
    answer: 'We have subtracted the value of any unused days on your current Day Pass from the cost of your new Season Pass',
  },
];

const purchaseFaqs = [
  {
    question: 'Will this change my pass status?',
    answer: 'No, your existing pass will remain as it is currently',
  },
];

const founderFaqs = [
  {
    question: 'Will this change my pass status?',
    answer: 'No, your existing pass will remain as it is currently',
  },

];

const giftEarlyRenewalFaqs = [
  {
    question: 'Will the person who bought my gift membership be charged?',
    answer: 'No, you will enter your card details at the checkout and you will then have an ongoing subscription for your pass type.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today and your subscription will charged at the renewal date.',
  },
  {
    question: 'Will I lose the remaining time on my current pass?',
    answer: 'No, your new pass will start after your current pass expires.',
  },
  {
    question: 'What if I choose not to take this offer?',
    answer: 'Your membership will expire at the current end date.',
  },
];

const giftUpgradeFaqs = [
  {
    question: 'Will the person who bought my gift membership be charged?',
    answer: 'No, you will enter your card details at the checkout and you will then have an ongoing subscription for your pass type.',
  },
  {
    question: 'When will my subscription renewal be?',
    answer: 'By starting one of our Season passes today, your next renewal date will be a year from today. Your original pass will not be continued.',
  },
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today and your subscription will charged at the renewal date.',
  },
  {
    question: 'How are our season passes different from my current pass?',
    answer: 'Our season passes are the most cost-effective options, covering you for unlimited days of carving throughout the whole 365-day window.',
  },
  {
    question: 'How is my pass upgrade price calculated?',
    answer: 'We have subtracted the value of any unused days on your current Day Pass from the cost of your new Season Pass',
  },
];

const giftPurchaseFaqs = [
  {
    question: 'Will this change my pass status?',
    answer: 'No, your current pass will not change and you will need to purchase a new pass to continue using Carv after this expires.',
  },
];

const retailEarlyRenewalFaqs = [
  {
    question: 'When will I be charged?',
    answer: 'If you choose to take the offer, you will be charged today and your subscription will charged at the renewal date.',
  },
  {
    question: 'Will I lose the remaining time on my current pass?',
    answer: 'No, your new pass will start after your current pass expires.',
  },
  {
    question: 'What if I choose not to take this offer?',
    answer: 'Your membership will expire at the current end date.',
  },
];

const retailPurchaseFaqs = [
  {
    question: 'Will this change my pass status?',
    answer: 'No, your current pass will not change and you will need to purchase a new pass to continue using Carv after this expires.',
  },
];

const FAQ = ({ offerType, offerSource, isFreeHardware }: {
  offerType: OfferType,
  offerSource: OfferSource | undefined,
  isFreeHardware: boolean
}) => (
  <div className="text-blue-900 my-24 px-12">
    <div className="text-24 font-bold">FAQs</div>
    {offerType === OfferType.HARDWARE_EARLY_RENEWAL
    && offerSource === OfferSource.SUBSCRIPTION
    && earlyRenewalFaqs.map((faq) => (
      <div className="mt-16" key={faq.question}>
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
    {offerType === OfferType.HARDWARE_UPGRADE_RENEWAL
    && offerSource === OfferSource.SUBSCRIPTION
    && upgradeFaqs.map((faq) => (
      <div className="mt-16" key={faq.question}>
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
    {offerType === OfferType.HARDWARE_ONLY
    && offerSource === OfferSource.SUBSCRIPTION
    && isFreeHardware && purchaseFaqs.map((faq) => (
      <div className="mt-16" key={faq.question}>
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
    {offerType === OfferType.HARDWARE_ONLY
      && offerSource === OfferSource.SUBSCRIPTION
      && !isFreeHardware
      ? purchaseFaqs.slice(0, 2).map((faq) => (
        <div className="mt-16" key={faq.question}>
          <p className="font-semibold text-20">{faq.question}</p>
          <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
        </div>
      )) : null}
    {/* This is what founders see */}
    {offerType === OfferType.HARDWARE_ONLY
      && offerSource === OfferSource.RETAIL
      && !isFreeHardware
      ? founderFaqs.slice(0, 2).map((faq) => (
        <div className="mt-16" key={faq.question}>
          <p className="font-semibold text-20">{faq.question}</p>
          <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
        </div>
      )) : null}
    {offerType === OfferType.HARDWARE_EARLY_RENEWAL
        && offerSource === OfferSource.GIFT
        && giftEarlyRenewalFaqs.map((faq) => (
          <div className="mt-16" key={faq.question}>
            <p className="font-semibold text-20">{faq.question}</p>
            <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
          </div>
        ))}
    {offerType === OfferType.HARDWARE_UPGRADE_RENEWAL
    && offerSource === OfferSource.GIFT
    && giftUpgradeFaqs.map((faq) => (
      <div className="mt-16" key={faq.question}>
        <p className="font-semibold text-20">{faq.question}</p>
        <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
      </div>
    ))}
    {offerType === OfferType.HARDWARE_ONLY
    && offerSource === OfferSource.GIFT
      && isFreeHardware
      && giftPurchaseFaqs.map((faq) => (
        <div className="mt-16" key={faq.question}>
          <p className="font-semibold text-20">{faq.question}</p>
          <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
        </div>
      ))}
    {offerType === OfferType.HARDWARE_ONLY && offerSource === OfferSource.GIFT && !isFreeHardware
      ? giftPurchaseFaqs.slice(0, 2).map((faq) => (
        <div className="mt-16" key={faq.question}>
          <p className="font-semibold text-20">{faq.question}</p>
          <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
        </div>
      )) : null}
    {offerType === OfferType.HARDWARE_EARLY_RENEWAL
        && offerSource === OfferSource.GIFT
        && retailEarlyRenewalFaqs.map((faq) => (
          <div className="mt-16" key={faq.question}>
            <p className="font-semibold text-20">{faq.question}</p>
            <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
          </div>
        ))}
    {offerType === OfferType.HARDWARE_ONLY
    && offerSource === OfferSource.GIFT
      && isFreeHardware
      && retailPurchaseFaqs.map((faq) => (
        <div className="mt-16" key={faq.question}>
          <p className="font-semibold text-20">{faq.question}</p>
          <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
        </div>
      ))}
    {offerType === OfferType.HARDWARE_ONLY && offerSource === OfferSource.GIFT && !isFreeHardware
      ? retailPurchaseFaqs.slice(0, 2).map((faq) => (
        <div className="mt-16" key={faq.question}>
          <p className="font-semibold text-20">{faq.question}</p>
          <p className="font-semibold text-16 text-grey-500">{faq.answer}</p>
        </div>
      )) : null}
  </div>
);

export default FAQ;
