// import LoadingButton from 'core/Components/LoadingButton';
import React, { useRef, useState } from 'react';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import OfferType from 'renewal/domain/model/OfferType';
import Carv2Sensors from 'images/Carv2Sensors.png';
import { ReactComponent as InfoIcon } from 'svg/info.svg';
import FAQ from './FAQ';
import OfferCard from './OfferCard';

interface OfferTemplateProps {
  offer: HardwareUpgradeOffer,
  currentOfferType: OfferType,
  cardOnClick: (acceptedOffer: HardwareUpgradeOffer) => void
  buttonOnClick: (acceptedOffer: HardwareUpgradeOffer) => void
  faqOnClick: (offerType: OfferType) => void
}

const NewHardwareOfferTemplate = (
  {
    offer, currentOfferType, faqOnClick, cardOnClick, buttonOnClick,
  }: OfferTemplateProps,
) => (
  <>
    <div className={`relative overflow-hidden shadow-grey-50%-sm w-full rounded-12 border flex flex-col py-24 ${currentOfferType === offer.type ? 'border-blue-400 bg-blue-250' : 'border-grey-500 bg-white'}`}>
      <div className="overflow-hidden pt-12 px-24 md:max-w-1080">
        <OfferCard
          offer={offer}
          cardOnClick={cardOnClick}
          buttonOnClick={buttonOnClick}
        />
      </div>
    </div>
    <button type="button" onClick={() => { faqOnClick(offer.type); }} className="underline mt-12 text-blue-400 font-bold">Learn more about this offer</button>
  </>
);

interface OffersTemplateProps {
  offers: HardwareUpgradeOffer[],
  currentOffer: HardwareUpgradeOffer,
  changeOffer: (offer: HardwareUpgradeOffer) => void,
  acceptOnClick: (acceptedOffer: HardwareUpgradeOffer) => void,
}

const NewHardwareOffersTemplate = ({ offers, currentOffer, changeOffer, acceptOnClick }
: OffersTemplateProps) => {
  const [offerTypeFaq, setOfferTypeFaq] = useState(currentOffer.type);
  const faqRef = useRef<HTMLDivElement>(null);

  const buttonOnClick = (offer: HardwareUpgradeOffer) => {
    if (offer.type === currentOffer.type) {
      acceptOnClick(offer);
    } else {
      setOfferTypeFaq(offer.type);
      changeOffer(offer);
    }
  };

  const cardOnClick = (offer: HardwareUpgradeOffer) => {
    setOfferTypeFaq(offer.type);
    changeOffer(offer);
  };

  const faqOnClick = (offerType: OfferType) => {
    setOfferTypeFaq(offerType);
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="max-w-780 mx-auto px-12">
      <div className="flex md:flex-row flex-col md:gap-30 mb-12 md:mb-0 justify-start md:px-50 px-12">

        <img className="md:w-[200px] md:h-200 w-150 h-150 mx-auto md:mx-0" src={Carv2Sensors} alt="tips and training" loading="lazy" />
        <div className="max-w-400 flex flex-col justify-center">
          <p className="text-blue-900 text-30 font-bold">Upgrade to Carv 2</p>
          <p>No inserts, no installation, simply clip and go.</p>
          <a href="https://getcarv.com/whats-new" target="_blank" className="underline text-blue-400 font-bold" rel="noreferrer"> Learn more</a>
        </div>
      </div>
      {/* Show Early renewal first */}
      {offers.sort((a) => {
        if (a.type === OfferType.HARDWARE_ONLY) return 1;
        return -1;
      }).map(
        (offer, index) => (
          <div key={offer.type}>
            <NewHardwareOfferTemplate
              offer={offer}
              cardOnClick={cardOnClick}
              buttonOnClick={buttonOnClick}
              currentOfferType={currentOffer.type}
              faqOnClick={faqOnClick}
            />
            {index !== offers.length - 1 && <p className="flex justify-center my-24 text-24 font-bold">OR</p>}
          </div>
        ),
      )}
      <div className="flex mt-32 md:text-left text-20 font-bold text-blue-900  w-full text-left mx-12 md:mx-0">
        <p className="mr-12">Carv 2 shipping now.</p>
        <a target="_blank" className="stroke-blue-400 fill-blue-400" href="https://getcarv.com/blog/shipping-estimates" rel="noreferrer">
          <InfoIcon />
        </a>
      </div>
      <div ref={faqRef}>
        <FAQ
          offerType={offerTypeFaq}
          offerSource={currentOffer.source}
          isFreeHardware={currentOffer.discountedPrice === 0}
        />
      </div>
    </div>

  );
};

export default NewHardwareOffersTemplate;
