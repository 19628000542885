import LoadingButton from 'core/Components/LoadingButton';
import utils from 'core/utils';
import React, { useState } from 'react';
import DatePicker from 'renewal/Components/DatePicker';
import RenewalOfferButton from 'renewal/Components/RenewalOfferButton';

interface NotSkiingTemplateProps {
  currentSubscriptionId: string,
  onClick: (date: Date, selectedReason: string) => void,
  nextPayment: Date,
  reasons: { text: string, value: string }[],
  isSubmitting: boolean
}

const NotSkiingTemplate = ({
  currentSubscriptionId, onClick, nextPayment, reasons, isSubmitting,
}
: NotSkiingTemplateProps) => {
  if (!nextPayment) return null;

  const [date, setDate] = useState(new Date(nextPayment));
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonError, setReasonError] = useState<boolean>(false);
  return (
    <section>
      <div className="w-full">
        <h5 className="h5 font-bold text-blue-900 mb-12">No problem, choose how long you&apos;d like to delay
          your
          membership for
        </h5>
        <p className="text-secondary bg-grey-300 p-12 rounded-12 mb-12">You will always have access to Carv for
          free
          with basic tracking and Ski IQ.
        </p>
        <div className="p-12">
          <DatePicker startDate={new Date(nextPayment)} date={date} setDate={setDate} />
        </div>

        <p className="h5 font-bold text-blue-900 mt-6 mb-12">Why are you delaying your membership?</p>

        <select
          className={`w-full border rounded-16 border-grey-300 bg-white hover:bg-grey-100 p-20 mb-12 ${reasonError ? 'ring-red-500 ring-2' : ''}`}
          onChange={(e) => {
            setReasonError(false);
            setSelectedReason(e.currentTarget.value);
          }}
        >
          <option value="" disabled selected>Select a reason</option>
          {reasons.map((reason) => (
            <option
              value={reason.value}
              className="px-4 py-20 text-left text-gray-700 hover:bg-gray-100"
            >
              {reason.text}
            </option>
          ))}
        </select>
      </div>
      <p className="text-center text-18 mb-12">
        You will not be charged anything until <br /><strong className="font-extrabold">{utils.toLongDate(date)}</strong>
      </p>
      {reasonError && <p className="text-red-500">Please select a reason</p>}
      <div className="relative group">
        <LoadingButton
          className="disabled:bg-blue-300"
          isSubmitting={isSubmitting}
          text="delay membership"
          onClick={() => {
            if (selectedReason === '') {
              setReasonError(true);
              return;
            }
            onClick(date, selectedReason);
          }}
          dataId="delay-button"
          dataSubscriptionId={currentSubscriptionId}
          dataDate={date.toISOString()}
        />
        <RenewalOfferButton subscriptionId={currentSubscriptionId} />
      </div>
    </section>
  );
};

export default NotSkiingTemplate;
